import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Title from './Title';
import Project from './Project';
import LazyLoad from 'react-lazyload';

import '../css/projects.scss';

class Projects extends React.Component {
  state = {
    categorySelect: '', // filter by on/off premise (blank str means all premises)
  };

  render() {
    const {
      projects,
      title,
      showLink,
      h1,
      // category,
      showFilter,
    } = this.props;
    const { categorySelect } = this.state;

    // const categoryName = category === "develop" ? "development" : "design"

    return (
      <section className="section projects-section">
        <div className="container">
          <Title title={title} h1={h1} />

          {showFilter && (
            <div className="filter-bar">
              <button
                type="button"
                className={classNames({
                  btn: true,
                  active: categorySelect === '',
                })}
                onClick={val => this.setState({ categorySelect: '' })}
              >
                all
              </button>
              <button
                type="button"
                className={classNames({
                  btn: true,
                  active: categorySelect === 'develop',
                })}
                onClick={val =>
                  this.setState({ categorySelect: 'develop' })
                }
              >
                development
              </button>
              <button
                type="button"
                className={classNames({
                  btn: true,
                  active: categorySelect === 'design',
                })}
                onClick={val =>
                  this.setState({ categorySelect: 'design' })
                }
              >
                design
              </button>
            </div>
          )}

          <div className="projects-wrapper">
            {projects
              // .filter(e => e.category === "develop")
              // .filter(e => !categorySelect || (e.category = categorySelect))
              .filter(
                e =>
                  !categorySelect ||
                  e.category === (categorySelect || ''),
              )

              .sort((a, b) => {
                if (a.title < b.title) return -1;
                if (a.title > b.title) return 1;
                return 0;
              })
              .map((project, index) => {
                return (
                  <LazyLoad
                    // height={200}
                    offset={100}
                    key={project.id}
                    once
                  >
                    <Project
                      key={project.id}
                      index={index}
                      {...project}
                    />
                  </LazyLoad>
                );
              })}
          </div>
          {showLink && (
            <Link to="/projects" className="btn center-btn">
              view all projects
            </Link>
          )}
        </div>
      </section>
    );
  }
}

Projects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape),
  title: PropTypes.string,
  showLink: PropTypes.bool,
  h1: PropTypes.bool,
  // category: ,
  showFilter: PropTypes.bool,
};

Projects.defaultProps = {
  projects: {},
  title: '',
  showLink: false,
  h1: false,
  // category: ,
  showFilter: false,
};

export default Projects;
