import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
// import { FaGithubSquare, FaShareSquare } from "react-icons/fa"
import { Link } from 'gatsby';

const Project = ({ id, slug, title, image, stack }) => {
  return (
    <Link to={`/projects/${slug}`} className="project" key={id}>
      <figure className="project">
        {image && (
          <Image
            fluid={image.childImageSharp.fluid}
            className="project-img"
          />
        )}
        <figcaption className="project-info">
          {/* <span className="project-number">0{index + 1}.</span> */}

          <div className="title">{title || 'default title'}</div>
          {/* <p className="project-desc">{description}</p> */}
          <ul className="project-stack">
            {stack.map(item => {
              return <li key={item.id}>{item.title}</li>;
            })}
          </ul>

          {/* <div className="project-links">
            <a href={github}>
              <FaGithubSquare className="project-icon" />
            </a>
            <a href={url}>
              <FaShareSquare className="project-icon" />
            </a>
          </div> */}
        </figcaption>
        <div className="overlay" />
      </figure>
    </Link>
  );
};

Project.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // url: PropTypes.string,
  // description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      fluid: PropTypes.shape({}),
    }),
  }),
  slug: PropTypes.string.isRequired,
  stack: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Project.defaultProps = {
  image: [],
};

export default Project;
